import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0a66bce1 = () => interopDefault(import('../pages/account-requests/index.vue' /* webpackChunkName: "pages/account-requests/index" */))
const _9ec40dd8 = () => interopDefault(import('../pages/ai-reports.vue' /* webpackChunkName: "pages/ai-reports" */))
const _50f02e58 = () => interopDefault(import('../pages/bonus/index.vue' /* webpackChunkName: "pages/bonus/index" */))
const _231588ed = () => interopDefault(import('../pages/boost-all.vue' /* webpackChunkName: "pages/boost-all" */))
const _52da10dc = () => interopDefault(import('../pages/bug-reports.vue' /* webpackChunkName: "pages/bug-reports" */))
const _0d79e8e6 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _4ac7c95a = () => interopDefault(import('../pages/faqs/index.vue' /* webpackChunkName: "pages/faqs/index" */))
const _366fa612 = () => interopDefault(import('../pages/feedback-funnel/index.vue' /* webpackChunkName: "pages/feedback-funnel/index" */))
const _0e1cdb24 = () => interopDefault(import('../pages/ht-alerts.vue' /* webpackChunkName: "pages/ht-alerts" */))
const _0335ebe3 = () => interopDefault(import('../pages/invoices/index.vue' /* webpackChunkName: "pages/invoices/index" */))
const _024aa0cc = () => interopDefault(import('../pages/journal-edits/index.vue' /* webpackChunkName: "pages/journal-edits/index" */))
const _2510c887 = () => interopDefault(import('../pages/lock-logs.vue' /* webpackChunkName: "pages/lock-logs" */))
const _2180f442 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _9400ea90 = () => interopDefault(import('../pages/manager-stats/index.vue' /* webpackChunkName: "pages/manager-stats/index" */))
const _fbf552b4 = () => interopDefault(import('../pages/manuals/index.vue' /* webpackChunkName: "pages/manuals/index" */))
const _55d6aeef = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _69ba28c6 = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _cac68250 = () => interopDefault(import('../pages/missed-payments/index.vue' /* webpackChunkName: "pages/missed-payments/index" */))
const _45a7a452 = () => interopDefault(import('../pages/my-team/index.vue' /* webpackChunkName: "pages/my-team/index" */))
const _41ec1090 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _537415de = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _7c64280e = () => interopDefault(import('../pages/payment-updates.vue' /* webpackChunkName: "pages/payment-updates" */))
const _219f108b = () => interopDefault(import('../pages/pl-stats/index.vue' /* webpackChunkName: "pages/pl-stats/index" */))
const _18a2609f = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _967c0032 = () => interopDefault(import('../pages/profiles/index.vue' /* webpackChunkName: "pages/profiles/index" */))
const _20caa846 = () => interopDefault(import('../pages/rejection-categories/index.vue' /* webpackChunkName: "pages/rejection-categories/index" */))
const _2819dce0 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _e5039a50 = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _a29a4220 = () => interopDefault(import('../pages/top-triggers/index.vue' /* webpackChunkName: "pages/top-triggers/index" */))
const _1949fd08 = () => interopDefault(import('../pages/traffic-stats/index.vue' /* webpackChunkName: "pages/traffic-stats/index" */))
const _1ba33710 = () => interopDefault(import('../pages/triggers/index.vue' /* webpackChunkName: "pages/triggers/index" */))
const _39ea8058 = () => interopDefault(import('../pages/update-stats/index.vue' /* webpackChunkName: "pages/update-stats/index" */))
const _57f8ff72 = () => interopDefault(import('../pages/wink-replies/index.vue' /* webpackChunkName: "pages/wink-replies/index" */))
const _484ac118 = () => interopDefault(import('../pages/manuals/new.vue' /* webpackChunkName: "pages/manuals/new" */))
const _1a0df566 = () => interopDefault(import('../pages/my-team/add.vue' /* webpackChunkName: "pages/my-team/add" */))
const _74147cdc = () => interopDefault(import('../pages/my-team/pending.vue' /* webpackChunkName: "pages/my-team/pending" */))
const _8ced2fc8 = () => interopDefault(import('../pages/news/CreateNewsModal.vue' /* webpackChunkName: "pages/news/CreateNewsModal" */))
const _7680a964 = () => interopDefault(import('../pages/news/EditNewsModal.vue' /* webpackChunkName: "pages/news/EditNewsModal" */))
const _41ee1439 = () => interopDefault(import('../pages/news/SeenByModal.vue' /* webpackChunkName: "pages/news/SeenByModal" */))
const _0b3247b7 = () => interopDefault(import('../pages/profiles/create.vue' /* webpackChunkName: "pages/profiles/create" */))
const _8503ea28 = () => interopDefault(import('../pages/profiles/pending.vue' /* webpackChunkName: "pages/profiles/pending" */))
const _d7f70bd2 = () => interopDefault(import('../pages/profiles/update-mixin.js' /* webpackChunkName: "pages/profiles/update-mixin" */))
const _633c532a = () => interopDefault(import('../pages/rejection-categories/new.vue' /* webpackChunkName: "pages/rejection-categories/new" */))
const _833dd5a2 = () => interopDefault(import('../pages/triggers/manage.vue' /* webpackChunkName: "pages/triggers/manage" */))
const _51638f3d = () => interopDefault(import('../pages/triggers/pending.vue' /* webpackChunkName: "pages/triggers/pending" */))
const _2e9a639d = () => interopDefault(import('../pages/update-stats/AddNewFeature.vue' /* webpackChunkName: "pages/update-stats/AddNewFeature" */))
const _78666211 = () => interopDefault(import('../pages/account-requests/_request.vue' /* webpackChunkName: "pages/account-requests/_request" */))
const _c6a03fa6 = () => interopDefault(import('../pages/activity-logs/_type.vue' /* webpackChunkName: "pages/activity-logs/_type" */))
const _1a5f7bf1 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _2b4b408b = () => interopDefault(import('../pages/my-team/_operator/index.vue' /* webpackChunkName: "pages/my-team/_operator/index" */))
const _0ee91110 = () => interopDefault(import('../pages/profile-duplicates/_country.vue' /* webpackChunkName: "pages/profile-duplicates/_country" */))
const _3a2d1a28 = () => interopDefault(import('../pages/profiles/_profile/index.vue' /* webpackChunkName: "pages/profiles/_profile/index" */))
const _65056552 = () => interopDefault(import('../pages/profiles/_profileold.vue' /* webpackChunkName: "pages/profiles/_profileold" */))
const _f36ab47a = () => interopDefault(import('../pages/triggers/_trigger.vue' /* webpackChunkName: "pages/triggers/_trigger" */))
const _28912561 = () => interopDefault(import('../pages/my-team/_operator/edit.vue' /* webpackChunkName: "pages/my-team/_operator/edit" */))
const _42898cca = () => interopDefault(import('../pages/my-team/_operator/lock-logs.vue' /* webpackChunkName: "pages/my-team/_operator/lock-logs" */))
const _60a52c0a = () => interopDefault(import('../pages/my-team/_operator/message-list.vue' /* webpackChunkName: "pages/my-team/_operator/message-list" */))
const _f324d2ce = () => interopDefault(import('../pages/my-team/_operator/profile-list.vue' /* webpackChunkName: "pages/my-team/_operator/profile-list" */))
const _13c8292c = () => interopDefault(import('../pages/my-team/_operator/trigger-list.vue' /* webpackChunkName: "pages/my-team/_operator/trigger-list" */))
const _774fdaca = () => interopDefault(import('../pages/profiles/_profile/photos.vue' /* webpackChunkName: "pages/profiles/_profile/photos" */))
const _2597e2c8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account-requests",
    component: _0a66bce1,
    name: "account-requests"
  }, {
    path: "/ai-reports",
    component: _9ec40dd8,
    name: "ai-reports"
  }, {
    path: "/bonus",
    component: _50f02e58,
    name: "bonus"
  }, {
    path: "/boost-all",
    component: _231588ed,
    name: "boost-all"
  }, {
    path: "/bug-reports",
    component: _52da10dc,
    name: "bug-reports"
  }, {
    path: "/dashboard",
    component: _0d79e8e6,
    name: "dashboard"
  }, {
    path: "/faqs",
    component: _4ac7c95a,
    name: "faqs"
  }, {
    path: "/feedback-funnel",
    component: _366fa612,
    name: "feedback-funnel"
  }, {
    path: "/ht-alerts",
    component: _0e1cdb24,
    name: "ht-alerts"
  }, {
    path: "/invoices",
    component: _0335ebe3,
    name: "invoices"
  }, {
    path: "/journal-edits",
    component: _024aa0cc,
    name: "journal-edits"
  }, {
    path: "/lock-logs",
    component: _2510c887,
    name: "lock-logs"
  }, {
    path: "/login",
    component: _2180f442,
    name: "login"
  }, {
    path: "/manager-stats",
    component: _9400ea90,
    name: "manager-stats"
  }, {
    path: "/manuals",
    component: _fbf552b4,
    name: "manuals"
  }, {
    path: "/message-reports",
    component: _55d6aeef,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _69ba28c6,
    name: "messages"
  }, {
    path: "/missed-payments",
    component: _cac68250,
    name: "missed-payments"
  }, {
    path: "/my-team",
    component: _45a7a452,
    name: "my-team"
  }, {
    path: "/news",
    component: _41ec1090,
    name: "news"
  }, {
    path: "/notifications",
    component: _537415de,
    name: "notifications"
  }, {
    path: "/payment-updates",
    component: _7c64280e,
    name: "payment-updates"
  }, {
    path: "/pl-stats",
    component: _219f108b,
    name: "pl-stats"
  }, {
    path: "/profile",
    component: _18a2609f,
    name: "profile"
  }, {
    path: "/profiles",
    component: _967c0032,
    name: "profiles"
  }, {
    path: "/rejection-categories",
    component: _20caa846,
    name: "rejection-categories"
  }, {
    path: "/settings",
    component: _2819dce0,
    name: "settings"
  }, {
    path: "/stats",
    component: _e5039a50,
    name: "stats"
  }, {
    path: "/top-triggers",
    component: _a29a4220,
    name: "top-triggers"
  }, {
    path: "/traffic-stats",
    component: _1949fd08,
    name: "traffic-stats"
  }, {
    path: "/triggers",
    component: _1ba33710,
    name: "triggers"
  }, {
    path: "/update-stats",
    component: _39ea8058,
    name: "update-stats"
  }, {
    path: "/wink-replies",
    component: _57f8ff72,
    name: "wink-replies"
  }, {
    path: "/manuals/new",
    component: _484ac118,
    name: "manuals-new"
  }, {
    path: "/my-team/add",
    component: _1a0df566,
    name: "my-team-add"
  }, {
    path: "/my-team/pending",
    component: _74147cdc,
    name: "my-team-pending"
  }, {
    path: "/news/CreateNewsModal",
    component: _8ced2fc8,
    name: "news-CreateNewsModal"
  }, {
    path: "/news/EditNewsModal",
    component: _7680a964,
    name: "news-EditNewsModal"
  }, {
    path: "/news/SeenByModal",
    component: _41ee1439,
    name: "news-SeenByModal"
  }, {
    path: "/profiles/create",
    component: _0b3247b7,
    name: "profiles-create"
  }, {
    path: "/profiles/pending",
    component: _8503ea28,
    name: "profiles-pending"
  }, {
    path: "/profiles/update-mixin",
    component: _d7f70bd2,
    name: "profiles-update-mixin"
  }, {
    path: "/rejection-categories/new",
    component: _633c532a,
    name: "rejection-categories-new"
  }, {
    path: "/triggers/manage",
    component: _833dd5a2,
    name: "triggers-manage"
  }, {
    path: "/triggers/pending",
    component: _51638f3d,
    name: "triggers-pending"
  }, {
    path: "/update-stats/AddNewFeature",
    component: _2e9a639d,
    name: "update-stats-AddNewFeature"
  }, {
    path: "/account-requests/:request?",
    component: _78666211,
    name: "account-requests-request"
  }, {
    path: "/activity-logs/:type?",
    component: _c6a03fa6,
    name: "activity-logs-type"
  }, {
    path: "/conversations/:id?",
    component: _1a5f7bf1,
    name: "conversations-id"
  }, {
    path: "/my-team/:operator?",
    component: _2b4b408b,
    name: "my-team-operator"
  }, {
    path: "/profile-duplicates/:country?",
    component: _0ee91110,
    name: "profile-duplicates-country"
  }, {
    path: "/profiles/:profile",
    component: _3a2d1a28,
    name: "profiles-profile"
  }, {
    path: "/profiles/:profileold",
    component: _65056552,
    name: "profiles-profileold"
  }, {
    path: "/triggers/:trigger",
    component: _f36ab47a,
    name: "triggers-trigger"
  }, {
    path: "/my-team/:operator?/edit",
    component: _28912561,
    name: "my-team-operator-edit"
  }, {
    path: "/my-team/:operator?/lock-logs",
    component: _42898cca,
    name: "my-team-operator-lock-logs"
  }, {
    path: "/my-team/:operator?/message-list",
    component: _60a52c0a,
    name: "my-team-operator-message-list"
  }, {
    path: "/my-team/:operator?/profile-list",
    component: _f324d2ce,
    name: "my-team-operator-profile-list"
  }, {
    path: "/my-team/:operator?/trigger-list",
    component: _13c8292c,
    name: "my-team-operator-trigger-list"
  }, {
    path: "/profiles/:profile/photos",
    component: _774fdaca,
    name: "profiles-profile-photos"
  }, {
    path: "/",
    component: _2597e2c8,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
